import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";

export function Meta({ description, lang, meta, title }) {
  const { site } = useStaticQuery(QUERY);
  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[{ name: `description`, content: metaDescription }].concat(meta)}
    />
  );
}

const QUERY = graphql `
  query {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
  }
`;

Meta.defaultProps = {
  lang: "de",
  meta: [],
  description: "",
};

Meta.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};
